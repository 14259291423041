@charset "UTF-8";
html {
  font-size: 62.5%;
  overflow-y: scroll !important;
}

body {
  margin: 0;
  background: #F7F7F7;
  line-height: 1.5;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  color: #53565A;
  font-family: "AvenirNextLTPro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-size: 1.6rem;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: inherit;
  outline: none;
  cursor: pointer !important;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a img {
  border: none;
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin: 0;
}

ul, ol {
  padding-left: 1.5em;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 700;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  color: inherit;
  border-radius: 0;
  box-shadow: none;
  display: block;
}

svg, img:not(.x2) {
  max-width: 100%;
  width: auto;
  height: auto;
}

.d-table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.d-table > * {
  display: table-cell;
  vertical-align: middle;
}

.clr {
  clear: both !important;
  float: none !important;
  display: block !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

td {
  padding-left: 2rem;
}

main {
  display: block;
}

.container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 1128px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 599px) {
  .container {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.bg-image {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.rwd-bg {
  background-position: center center;
  background-repeat: repeat;
}
.rwd-bg.full-bg {
  background-repeat: no-repeat;
  background-size: cover;
}

iframe {
  max-width: 100%;
}

@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}
@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 400;
  font-style: italic;
  src: url("../assets/fonts/AvenirNextLTPro-It.otf") format("opentype");
}
@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/AvenirNextLTPro-Medium.otf") format("opentype");
}
@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 600;
  font-style: italic;
  src: url("../assets/fonts/AvenirNextLTPro-MediumIt.otf") format("opentype");
}
@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
}
@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 700;
  font-style: italic;
  src: url("../assets/fonts/AvenirNextLTPro-BoldIt.otf") format("opentype");
}
nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  font-size: 1.2rem;
  background: #1E95AE;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
nav:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 767px) {
  nav {
    font-size: 24px;
  }
}
nav .container {
  position: relative;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
nav .container:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 767px) {
  nav .container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
nav .container .bg {
  position: absolute;
  height: 200%;
  left: 0;
  top: 0;
  background: url("../assets/nav-bg.png") no-repeat right bottom;
  background-size: 100% 100%;
  width: 15rem;
}
@media (max-width: 767px) {
  nav .container .bg {
    width: 110px;
  }
}
@media (max-width: 599px) {
  nav .container .bg {
    width: 102.5px;
  }
}
nav .container .bg div {
  position: absolute;
  right: 100%;
  top: 0;
  width: 50vw;
  height: 100%;
  background: url("../assets/nav-bg-over.png") repeat-x;
  background-size: auto 100%;
}
nav .logo {
  display: block;
  float: left;
  position: relative;
  z-index: 2;
  width: 7.6rem;
}
nav .logo img {
  display: block;
  width: 100%;
  height: auto;
}
nav #menu-container {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 2;
  float: right;
}
nav #menu-container:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 767px) {
  nav #menu-container {
    position: fixed;
    left: 0;
    top: 44px;
    background: #1E95AE;
    height: 80%;
    height: calc(100% - 44px);
    width: 100%;
    display: none;
    overflow-y: auto;
    padding: 0 14px;
  }
  .show-menu nav #menu-container {
    display: block;
  }
}
@media (max-width: 599px) {
  nav #menu-container {
    top: 41px;
    height: calc(100% - 41px);
  }
}
nav #menu-container .menu {
  list-style: none;
  padding: 0;
  margin: 0;
  float: right;
  display: block;
}
nav #menu-container .menu:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 767px) {
  nav #menu-container .menu {
    float: none;
    text-align: right;
  }
}
nav #menu-container .menu .hide {
  display: none !important;
}
nav #menu-container .menu > li {
  float: left;
  margin: 0 0 0 0.7rem;
  position: relative;
}
nav #menu-container .menu > li:first-child {
  margin-left: 0;
}
nav #menu-container .menu > li:last-child > a, nav #menu-container .menu > li:last-child > span {
  padding-right: 0;
}
@media (max-width: 767px) {
  nav #menu-container .menu > li {
    float: none;
    margin: 16px 0 0 0 !important;
  }
  nav #menu-container .menu > li:after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }
}
nav #menu-container .menu > li a, nav #menu-container .menu > li .el-click {
  display: block;
  position: relative;
  line-height: 1.8rem;
  padding: 0.5rem 1.4rem;
  z-index: 4;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 1025px) {
  nav #menu-container .menu > li a:hover, nav #menu-container .menu > li .el-click:hover {
    color: #E57200 !important;
    border-color: #E57200 !important;
  }
}
@media (max-width: 767px) {
  nav #menu-container .menu > li a, nav #menu-container .menu > li .el-click {
    display: inline-block;
    line-height: 1.2;
    padding: 10px 0 !important;
    background: none !important;
  }
}
nav #menu-container .menu > li span.menu-el {
  display: block;
  padding: 0.5rem 2rem 0.5rem 1.4rem;
  line-height: 1.8rem;
  z-index: 4;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767px) {
  nav #menu-container .menu > li span.menu-el {
    display: inline-block;
    line-height: 1.2;
    padding: 10px 0 !important;
    background: none !important;
  }
}
nav #menu-container .menu > li.with-submenu > a, nav #menu-container .menu > li.with-submenu > span {
  padding-right: 2rem;
}
nav #menu-container .menu > li.with-submenu > a span.arr, nav #menu-container .menu > li.with-submenu > span span.arr {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  display: block;
  right: 0.7rem;
  top: 50%;
  margin-top: -0.5rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background: url("../assets/arr-down-3.svg") no-repeat center center;
  background-size: 0.8rem auto;
}
@media (max-width: 767px) {
  nav #menu-container .menu > li.with-submenu > a span.arr, nav #menu-container .menu > li.with-submenu > span span.arr {
    display: none !important;
  }
}
nav #menu-container .menu > li.with-submenu > a.show-submenu span.arr, nav #menu-container .menu > li.with-submenu > span.show-submenu span.arr {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (min-width: 768px) {
  nav #menu-container .menu > li.with-submenu > a.show-submenu + ul, nav #menu-container .menu > li.with-submenu > span.show-submenu + ul {
    display: block;
  }
}
@media (min-width: 1025px) {
  nav #menu-container .menu > li.with-submenu > a:hover span.arr, nav #menu-container .menu > li.with-submenu > span:hover span.arr {
    background-image: url("../assets/arr-down-active.svg");
  }
}
@media (min-width: 1025px) {
  nav #menu-container .menu > li.with-submenu:hover ul {
    display: block;
  }
}
nav #menu-container .menu > li.active.with-submenu > a {
  background-image: url("../assets/arr-down-active.svg");
}
nav #menu-container .menu > li.join {
  margin-left: 4rem;
}
nav #menu-container .menu > li.join a {
  border: 1px solid #0085AD;
  border-radius: 1000px;
  color: #0085AD;
}
@media (max-width: 767px) {
  nav #menu-container .menu > li.join a {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
nav #menu-container .menu > li.login {
  margin-left: 2.4rem;
}
nav #menu-container .menu > li.login a {
  color: #0085AD;
  padding-left: 0;
}
nav #menu-container .menu > li ul {
  text-align: center;
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 100%;
}
@media (max-width: 767px) {
  nav #menu-container .menu > li ul {
    display: block;
    position: static;
    top: auto;
    left: auto;
    width: auto;
    text-align: right;
    font-size: 20px;
  }
  nav #menu-container .menu > li ul:before {
    display: none !important;
  }
}
nav #menu-container .menu > li ul:before {
  content: "";
  display: block;
  position: absolute;
  background: #1E95AE;
  left: 0;
  top: 0;
  height: calc(100% + 5.8rem);
  margin-top: -5.8rem;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
nav #menu-container .menu > li ul li {
  display: block;
  position: relative;
  z-index: 4;
}
nav #menu-container .menu > li ul li a {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
@media (max-width: 767px) {
  nav #menu-container .menu > li ul li a {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

#menu-btn {
  width: 20px;
  display: none;
  float: right;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 2;
  position: relative;
  margin-top: 5px;
}
#menu-btn span {
  display: block;
  height: 2px;
  margin-top: 5px;
  width: 20px;
  background: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#menu-btn span:first-child {
  margin-top: 0 !important;
}
.show-menu #menu-btn span:nth-child(1) {
  -webkit-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}
.show-menu #menu-btn span:nth-child(2) {
  opacity: 0;
}
.show-menu #menu-btn span:nth-child(3) {
  -webkit-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}
@media (max-width: 767px) {
  #menu-btn {
    display: block;
  }
}

h1 {
  font-size: 4.5rem;
  line-height: 1.1;
}
@media (max-width: 1128px) {
  h1 {
    font-size: 4rem;
  }
}
@media (max-width: 1024px) {
  h1 {
    font-size: 3.4rem;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 2.6rem;
  }
}

h2 {
  font-size: 4.2rem;
  line-height: 1.1;
}
@media (max-width: 1128px) {
  h2 {
    font-size: 3.6rem;
  }
}
@media (max-width: 1024px) {
  h2 {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 2.4rem;
  }
}

h3, .people-single .text .content .title, .people-list .people-intro .container .content .title, .water-tower-1 .title, .faq-section .title, .standard-text .title, .intro-3 .text .title {
  font-size: 3.5rem;
  line-height: 1.2;
}
@media (max-width: 1128px) {
  h3, .people-single .text .content .title, .people-list .people-intro .container .content .title, .water-tower-1 .title, .faq-section .title, .standard-text .title, .intro-3 .text .title {
    font-size: 3rem;
  }
}
@media (max-width: 1024px) {
  h3, .people-single .text .content .title, .people-list .people-intro .container .content .title, .water-tower-1 .title, .faq-section .title, .standard-text .title, .intro-3 .text .title {
    font-size: 2.6rem;
  }
}
@media (max-width: 767px) {
  h3, .people-single .text .content .title, .people-list .people-intro .container .content .title, .water-tower-1 .title, .faq-section .title, .standard-text .title, .intro-3 .text .title {
    font-size: 2.2rem;
  }
}

h4, .people-list .people-featured .people-block .text .title, .water-tower-2 .blocks .block .text, .intro-6 .blocks .block .title, .intro-4 .text .title, .intro-3, .intro-2 .container-in > *.text, .main-banner .container .text {
  font-size: 2.5rem;
  line-height: 1.2;
}
@media (max-width: 1128px) {
  h4, .people-list .people-featured .people-block .text .title, .water-tower-2 .blocks .block .text, .intro-6 .blocks .block .title, .intro-4 .text .title, .intro-3, .intro-2 .container-in > *.text, .main-banner .container .text {
    font-size: 2.2rem;
  }
}
@media (max-width: 1024px) {
  h4, .people-list .people-featured .people-block .text .title, .water-tower-2 .blocks .block .text, .intro-6 .blocks .block .title, .intro-4 .text .title, .intro-3, .intro-2 .container-in > *.text, .main-banner .container .text {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  h4, .people-list .people-featured .people-block .text .title, .water-tower-2 .blocks .block .text, .intro-6 .blocks .block .title, .intro-4 .text .title, .intro-3, .intro-2 .container-in > *.text, .main-banner .container .text {
    font-size: 1.8rem;
  }
}

h5 {
  font-size: 2rem;
}
@media (max-width: 1128px) {
  h5 {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  h5 {
    font-size: 1.6rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
}

hr {
  display: block;
  height: 1px;
  width: 100%;
  padding: 0;
  border: none;
  max-width: 12rem;
  border-top: 1px solid #0085AD;
  margin: 1rem auto !important;
}

.people-list .people-featured .people-block, .water-tower-2 .blocks .block .img img, .water-tower-1 .block, .faq-section .faqs, .standard-text .text, .intro-1 .container-in {
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

.sections {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.sections.no-bottom {
  padding-bottom: 0 !important;
}
@media (max-width: 767px) {
  .sections {
    padding-top: 44px;
    padding-bottom: 44px;
  }
}
@media (max-width: 599px) {
  .sections {
    padding-top: 41px;
    padding-bottom: 41px;
  }
}

main a {
  color: #0085AD;
}
main .alignleft {
  float: left;
  margin: 0 2rem 1.5rem 0;
}
main .alignright {
  float: right;
  margin: 0 0 1.5rem 2rem;
}
main .aligncenter {
  display: block;
  margin: 0 auto;
}
@media (min-width: 1025px) {
  main a:hover {
    text-decoration: underline;
  }
}
main th, main td {
  vertical-align: top;
  padding: 10px;
}
@media (max-width: 767px) {
  main th, main td {
    padding: 6px;
  }
}
main ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
main ul:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
main ul > li {
  margin: 0.3rem 0 0 0;
  padding-left: 1.4rem;
  background: url("../assets/li.png") no-repeat 0 0.5em;
  background-size: 5px auto;
  position: relative;
}
main ul > li:first-child {
  margin-top: 0;
}

.js .anim-block {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  opacity: 0;
}
.js .anim-block.active.step-1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.js .anim-block.active.step-2 {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.js .anim-block.active.step-3 {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
.js .anim-block.active.step-4 {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
.js .anim-block.active.step-5 {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.js .anim-block.active.step-6 {
  -webkit-transition-delay: 1.8s;
  transition-delay: 1.8s;
}
.js .anim-block.active.step-7 {
  -webkit-transition-delay: 2.1s;
  transition-delay: 2.1s;
}
.js .anim-block.active.step-8 {
  -webkit-transition-delay: 2.4s;
  transition-delay: 2.4s;
}
.js .anim-block.active.step-9 {
  -webkit-transition-delay: 2.7s;
  transition-delay: 2.7s;
}
.js .anim-block.active.step-10 {
  -webkit-transition-delay: 3s;
  transition-delay: 3s;
}

.js .anim-block-rev {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}

.js .anim-block-top {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}

.js .anim-block-bottom {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}

.js .anim-block-opac {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 0;
}

.js .anim-block.active {
  -webkit-transform: translateX(0px) translateY(0px) scale(1);
  transform: translateX(0px) translateY(0px) scale(1);
  opacity: 1;
}

.js .anim-block-scale > * {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.js .anim-block-scale.active > * {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.parallax-window {
  position: relative;
  overflow: hidden;
}
.parallax-window img {
  display: block;
  max-width: 100%;
  width: auto;
}
.parallax-window .parallax-window-in {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 120%;
  z-index: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.parallax-window .parallax-window-in.no-anim {
  -webkit-transition: none;
  transition: none;
}
.parallax-window .parallax-window-in img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  max-width: none;
}

#container {
  overflow: hidden;
}

.slides {
  position: relative;
}
.slides div {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  opacity: 0;
  z-index: 2;
  -webkit-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
.slides div:first-child {
  position: relative;
  height: auto;
}
.slides div.active {
  opacity: 1;
  z-index: 4;
}
.slides div img {
  display: block;
  opacity: 0;
}
.slides ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 3rem;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  z-index: 10;
}
.slides ul:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
.slides ul li {
  padding: 0;
  display: inline-block;
  margin: 0.8rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #fff;
  opacity: 0.4;
  cursor: pointer;
}
.slides ul li.active {
  opacity: 1;
}

.main-banner {
  color: #fff;
  text-align: center;
  background: #53565A;
  position: relative;
}
.main-banner video, .main-banner iframe {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 3;
  outline: none;
}
.play-video .main-banner video, .play-video .main-banner iframe {
  z-index: 10;
}
.main-banner .main-banner-poster {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #53565A url("../assets/home-poster.jpg") no-repeat center center;
  background-size: cover;
  z-index: 4;
}
.main-banner .container {
  max-height: calc(100vh - 6rem);
  height: 56.2225475842vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  z-index: 7;
}
@media (max-width: 767px) {
  .main-banner .container {
    height: auto;
  }
}
.main-banner .container .text {
  padding-top: 10%;
  padding-bottom: 20%;
}
.main-banner .container .text p {
  max-width: 47rem;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .main-banner .container .text {
    padding-top: 8rem;
    padding-bottom: 18rem;
  }
}
.main-banner .container .text .text-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 6rem;
  display: none;
}
@media (max-width: 767px) {
  .main-banner .container .text .text-bottom {
    bottom: 2rem;
  }
}
.main-banner .container .text .text-bottom.with-video {
  display: block;
}
.main-banner .container .text .text-bottom #main-banner-video-btn {
  display: table;
  margin: 0 auto 3rem auto;
  color: #0085AD;
  background: #fff;
  border-radius: 50px;
  font-size: 1.6rem;
  padding: 1.3rem 3.9rem;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 1;
  visibility: visible;
}
.main-banner .container .text .text-bottom .scroll-down {
  margin: 0 auto;
  cursor: pointer;
  width: 2.8rem;
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 1;
  visibility: visible;
}
.main-banner .container .text .text-bottom .scroll-down img {
  display: block;
  width: 100%;
  -webkit-animation: updown 0.6s linear 0s infinite alternate;
  animation: updown 0.6s linear 0s infinite alternate;
}
@-webkit-keyframes updown {
  from {
    -webkit-transform: translateY(-2px);
  }
  to {
    -webkit-transform: translateY(2px);
  }
}
@keyframes updown {
  from {
    transform: translateY(-2px);
  }
  to {
    transform: translateY(2px);
  }
}

.intro-1 {
  background: url("../assets/intro-1-bg.png") no-repeat 0 0;
  background-size: 100% auto;
  padding-top: 13rem;
}
@media (max-width: 599px) {
  .intro-1 {
    padding-top: 28px;
  }
}
.intro-1 .container-in {
  background: #fff;
}
.intro-1 .block {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.intro-1 .block:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 599px) {
  .intro-1 .block {
    display: block;
    display: flex;
    flex-direction: column;
  }
}
.intro-1 .block > * {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 599px) {
  .intro-1 .block > * {
    display: block;
  }
}
.intro-1 .block .img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .intro-1 .block .img {
    width: 50% !important;
  }
}
@media (max-width: 599px) {
  .intro-1 .block .img {
    width: 100% !important;
    order: 1;
  }
  .intro-1 .block .img:after {
    padding-top: 56.25%;
    content: "";
    display: block;
  }
}
.intro-1 .block .text {
  font-size: 2rem;
  line-height: 1.2;
  padding: 11% 0;
}
@media (max-width: 767px) {
  .intro-1 .block .text {
    width: 50% !important;
  }
}
@media (max-width: 599px) {
  .intro-1 .block .text {
    width: 100% !important;
    order: 2;
  }
}
.intro-1 .block .text .text-in {
  max-width: 29rem;
  padding: 0 2rem;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 599px) {
  .intro-1 .block .text .text-in {
    max-width: none;
  }
}
.intro-1 .block .text .text-in a {
  font-size: 1.6rem;
}
.intro-1 .block:nth-child(1) .text {
  width: 38.0514705882%;
}
.intro-1 .block:nth-child(1) .img {
  width: 61.9485294118%;
  background-image: url("../assets/intro-1.jpg");
}
.intro-1 .block:nth-child(2) .text {
  width: 61.9485294118%;
}
.intro-1 .block:nth-child(2) .img {
  width: 38.0514705882%;
  background-image: url("../assets/intro-2.jpg");
}

.intro-2 {
  padding-top: 15rem;
}
@media (max-width: 599px) {
  .intro-2 {
    padding-top: 28px;
  }
}
.intro-2 .container:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
.intro-2 .container-in {
  display: table;
  border-collapse: collapse;
}
@media (max-width: 599px) {
  .intro-2 .container-in {
    display: block;
    display: flex;
    flex-direction: column;
  }
}
.intro-2 .container-in > * {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}
@media (max-width: 599px) {
  .intro-2 .container-in > * {
    display: block;
    width: 100%;
  }
}
.intro-2 .container-in > *.img {
  vertical-align: bottom;
}
@media (max-width: 767px) {
  .intro-2 .container-in > *.img {
    width: 40%;
  }
}
@media (max-width: 599px) {
  .intro-2 .container-in > *.img {
    order: 2;
    width: 100%;
  }
}
.intro-2 .container-in > *.img img {
  display: block;
  width: 66.7279411765%;
  float: right;
}
@media (max-width: 767px) {
  .intro-2 .container-in > *.img img {
    float: none;
    width: 100%;
  }
}
@media (max-width: 599px) {
  .intro-2 .container-in > *.img img {
    margin: 0 auto;
    max-width: 240px;
    width: 50%;
  }
}
.intro-2 .container-in > *.text {
  text-align: center;
  color: #0085AD;
  padding: 2% 0 5% 3rem;
}
@media (max-width: 599px) {
  .intro-2 .container-in > *.text {
    order: 1;
  }
}
.intro-2 .container-in > *.text .links {
  margin-top: 8rem;
  text-align: center;
}
.intro-2 .container-in > *.text .links a {
  display: inline-block;
  margin: 0 0.8rem;
}
.intro-2 .container-in > *.text .links a img {
  display: block;
  height: 4rem;
  width: auto;
}

.intro-3 {
  color: #fff;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/intro-3b.jpg");
  padding-bottom: 25rem;
}
@media (max-width: 599px) {
  .intro-3 {
    padding-bottom: 18vw;
  }
}
.intro-3 .text {
  background: url("../assets/shape-2.png") no-repeat 0 100%;
  background-size: 100% auto;
  padding: 7vw 0 11vw 0;
  min-height: 36vw;
}
@media (max-width: 767px) {
  .intro-3 .text {
    padding-bottom: 12vw;
  }
}
@media (max-width: 599px) {
  .intro-3 .text {
    min-height: 0;
    padding-top: 4rem;
    padding-bottom: 27vw;
  }
}
.intro-3 .text .title {
  width: 66.6666666667%;
  margin-bottom: 7rem;
}
@media (max-width: 767px) {
  .intro-3 .text .title {
    width: 100%;
  }
}
@media (max-width: 599px) {
  .intro-3 .text .title {
    margin-bottom: 4rem;
  }
}
.intro-3 .text .text-in {
  width: 41.6666666667%;
}
@media (max-width: 1023px) {
  .intro-3 .text .text-in {
    width: 50%;
  }
}
@media (max-width: 599px) {
  .intro-3 .text .text-in {
    width: 100%;
  }
}

.intro-4 {
  padding: 6rem 0 8rem 0;
}
@media (max-width: 599px) {
  .intro-4 {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
.intro-4 .text {
  max-width: 54rem;
  margin: 0 auto;
  text-align: center;
}
.intro-4 .text .title {
  color: #0085AD;
  font-weight: 600;
  margin-bottom: 3rem;
}
.intro-4 .text .intro {
  font-size: 2rem;
}
.intro-4 .text .blocks {
  margin-top: 4rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.6rem;
  grid-row-gap: 1.6rem;
}
@media (max-width: 599px) {
  .intro-4 .text .blocks {
    margin-top: 28px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 14px;
  }
}
.intro-4 .text .blocks .block {
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
  padding: 1.6rem;
}
@media (max-width: 599px) {
  .intro-4 .text .blocks .block {
    padding: 14px;
  }
}
.intro-4 .text .blocks .block strong {
  display: block;
}
.intro-4 .text .blocks .block .icon {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  max-width: 12.8rem;
  margin: 0 auto 1rem auto;
}
.intro-4 .text .blocks .block .icon:after {
  padding-top: 100%;
  content: "";
  display: block;
}
.intro-4 .text .blocks .block .number {
  position: absolute;
  width: 106%;
  left: -3%;
  top: 50%;
  margin-top: -1rem;
  background: #5FB6CD;
  color: #fff;
  border-radius: 1000px;
  text-align: center;
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: 600;
  padding: 0.9rem 0.6rem 0.5rem 0.6rem;
}
.intro-4 .text .blocks .block:nth-child(1) {
  -ms-grid-column: 1;
}
.intro-4 .text .blocks .block:nth-child(1) .icon {
  background-image: url("../assets/icon-1.png");
}
.intro-4 .text .blocks .block:nth-child(2) {
  -ms-grid-column: 2;
}
.intro-4 .text .blocks .block:nth-child(2) .icon {
  background-image: url("../assets/icon-2.png");
}
.intro-4 .text .blocks .block:nth-child(3) {
  -ms-grid-column: 3;
}
.intro-4 .text .blocks .block:nth-child(3) .icon {
  background-image: url("../assets/icon-3.png");
}
.intro-4 .text .info-block {
  margin-top: 1.6rem;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
  padding: 32px 14%;
  text-align: center;
}
.intro-4 .text .info-block .number {
  display: block;
  margin: 0 auto 1rem auto;
  font-size: 2.2rem;
  line-height: 2.6rem;
  padding: 1.5rem;
  font-weight: 600;
  color: #5FB6CD;
  text-align: center;
  max-width: 27.4rem;
  background: url("../assets/number-bg.png") no-repeat center center;
  background-size: contain;
}
.intro-4 .text .info-block strong {
  display: block;
}

.intro-6 {
  padding-top: 10rem;
}
@media (max-width: 599px) {
  .intro-6 {
    padding-top: 28px;
  }
}
.intro-6 .blocks {
  padding-left: 3%;
  padding-right: 3%;
}
.intro-6 .blocks:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 599px) {
  .intro-6 .blocks {
    padding: 0;
  }
}
.intro-6 .blocks .block {
  margin-bottom: 4.8rem;
}
.intro-6 .blocks .block .title {
  font-weight: 600;
  color: #0085AD;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .intro-6 .blocks .block .title {
    margin-bottom: 14px;
  }
}
.intro-6 .blocks .block .text {
  background: #fff;
  padding: 28px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}
@media (max-width: 767px) {
  .intro-6 .blocks .block .text {
    padding: 20px !important;
  }
}
@media (max-width: 599px) {
  .intro-6 .blocks .block .text {
    padding: 14px !important;
  }
}
.intro-6 .blocks .block:nth-child(1), .intro-6 .blocks .block:nth-child(3) {
  width: 46.6666666667%;
  float: left;
}
.intro-6 .blocks .block:nth-child(2) {
  width: 46.6666666667%;
  float: right;
}
.intro-6 .blocks .block:nth-child(4) {
  clear: both;
}
.intro-6 .blocks .block:nth-child(4) .text {
  padding: 8% 11%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 11%;
  grid-row-gap: 10rem;
}
@media (max-width: 767px) {
  .intro-6 .blocks .block:nth-child(4) .text {
    padding: 4rem !important;
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
  }
}
@media (max-width: 599px) {
  .intro-6 .blocks .block:nth-child(4) .text {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    padding: 14px !important;
    grid-column-gap: 14px;
    grid-row-gap: 0;
  }
}
.intro-6 .blocks .block:nth-child(4) .text .col {
  text-align: center;
  padding: 0 10px;
}
.intro-6 .blocks .block:nth-child(4) .text .col .icon {
  display: block;
  height: 4.8rem;
  max-width: none;
  margin: 0 auto 2.4rem auto;
}
@media (max-width: 599px) {
  .intro-6 .blocks .block:nth-child(4) .text .col .icon {
    margin: 14px auto 0 auto;
  }
}
.intro-6 .blocks .block:nth-child(4) .text .col:nth-child(1) {
  -ms-grid-column: 1;
}
.intro-6 .blocks .block:nth-child(4) .text .col:nth-child(2) {
  -ms-grid-column: 2;
}
.intro-6 .blocks .block:nth-child(4) .text .col:nth-child(3) {
  -ms-grid-column: 3;
}
@media (max-width: 599px) {
  .intro-6 .blocks .block:nth-child(4) .text .col {
    padding: 14px 0 0 0 !important;
  }
  .intro-6 .blocks .block:nth-child(4) .text .col:first-child {
    padding-top: 0 !important;
  }
}
@media (max-width: 599px) {
  .intro-6 .blocks .block {
    width: 100% !important;
    float: none !important;
  }
}

.standard-text {
  background: url("../assets/text-bg.png") no-repeat 0 0;
  background-size: 100% auto;
}
@media (max-width: 599px) {
  .standard-text {
    background-size: 200% auto;
  }
}
.standard-text .title {
  text-align: center;
  font-weight: 600;
  padding: 7rem 0;
  color: #fff;
}
.standard-text .text {
  background: #fff;
  padding: 6% 8%;
}
@media (max-width: 599px) {
  .standard-text .text {
    padding: 14px;
  }
}
.standard-text .text strong {
  color: #0085AD;
  font-weight: 600;
}
.standard-text .text h3 {
  font-weight: 400;
  color: #0085AD;
}
.standard-text .text h5 {
  font-weight: 400;
  color: #0085AD;
}

.faq-section .title {
  text-align: center;
  font-weight: 600;
  padding: 7rem 0;
  color: #0085AD;
}
.faq-section .faqs {
  max-width: 72rem;
  margin: 0 auto;
  background: #fff;
  padding: 6% 8%;
}
@media (max-width: 599px) {
  .faq-section .faqs {
    padding: 14px;
  }
}
.faq-section .faqs strong {
  color: #0085AD;
  font-weight: 600;
}
.faq-section .faqs .faq {
  border-bottom: 1px solid rgba(0, 133, 173, 0.2);
}
.faq-section .faqs .faq:last-child {
  border-bottom: none;
}
.faq-section .faqs .faq .q {
  color: #0085AD;
  padding: 2rem 0;
  cursor: pointer;
  padding-right: 2rem;
  background: url("../assets/arr-down-2.svg") no-repeat right center;
  background-size: 8px auto;
}
.faq-section .faqs .faq .q.active {
  background-image: url("../assets/arr-up-2.svg");
}
.faq-section .faqs .faq .q.active + .a {
  display: block;
}
.faq-section .faqs .faq .a {
  padding-bottom: 2rem;
  display: none;
}

.water-tower-1 {
  position: relative;
  z-index: 2;
}
.water-tower-1 .title {
  width: 83.3333333333%;
  margin: 0 auto;
  padding: 13rem 0;
  text-align: center;
  color: #0085AD;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .water-tower-1 .title {
    padding: 10rem 0;
  }
}
@media (max-width: 599px) {
  .water-tower-1 .title {
    padding: 28px 0;
  }
}
.water-tower-1 .block {
  width: 83.3333333333%;
  margin: 0 auto;
  background: #fff;
}
@media (max-width: 599px) {
  .water-tower-1 .block {
    width: 100%;
  }
}
.water-tower-1 .block > img {
  display: block;
  width: 100%;
}
.water-tower-1 .block .text {
  max-width: 60%;
  text-align: center;
  font-size: 2rem;
  line-height: 1.2;
  margin: 0 auto;
  padding: 11% 0;
}
@media (max-width: 599px) {
  .water-tower-1 .block .text {
    max-width: none;
    padding: 28px;
  }
}

.water-tower-2 {
  background: url("../assets/water-tower-bg.png") no-repeat center center;
  background-size: 100% 100%;
  margin-top: -18rem;
  padding-top: 34rem;
  padding-bottom: 5rem;
  position: relative;
  z-index: 1;
  margin-bottom: 14rem;
}
@media (max-width: 599px) {
  .water-tower-2 {
    margin-top: 28px;
    padding-top: 0;
    background-size: auto 100%;
    background-position: 100% 100%;
    padding-bottom: 80px;
    margin-bottom: 28px;
  }
}
.water-tower-2 .blocks .block {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 599px) {
  .water-tower-2 .blocks .block {
    display: block;
    margin-top: 28px;
  }
  .water-tower-2 .blocks .block:first-child {
    margin-top: 0 !important;
  }
}
@media (max-width: 599px) {
  .water-tower-2 .blocks .block .img {
    padding: 0 !important;
  }
}
.water-tower-2 .blocks .block .img img {
  width: 100%;
  display: block;
}
@media (max-width: 1350px) {
  .water-tower-2 .blocks .block .img img {
    margin-left: 0 !important;
  }
}
.water-tower-2 .blocks .block .text {
  color: #fff;
  padding: 40px 0;
}
@media (max-width: 1024px) {
  .water-tower-2 .blocks .block .text {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .water-tower-2 .blocks .block .text {
    padding: 28px 0 0 0 !important;
  }
}
.water-tower-2 .blocks .block:nth-child(2n+1) .text {
  -ms-grid-column: 1;
  grid-column: 1/2;
  -ms-grid-row: 1;
  grid-row: 1;
  padding-right: 16.6666666667%;
}
.water-tower-2 .blocks .block:nth-child(2n+1) .img {
  -ms-grid-column: 2;
  grid-column: 2/3;
  -ms-grid-row: 1;
  grid-row: 1;
}
.water-tower-2 .blocks .block:nth-child(2n+1) .img img {
  margin-top: 12%;
  margin-left: 16.6666666667%;
}
.water-tower-2 .blocks .block:nth-child(2n) {
  align-items: center;
}
.water-tower-2 .blocks .block:nth-child(2n) .text {
  -ms-grid-column: 2;
  grid-column: 2/3;
  -ms-grid-row: 1;
  grid-row: 1;
  padding-left: 16.6666666667%;
}
.water-tower-2 .blocks .block:nth-child(2n) .img {
  -ms-grid-column: 1;
  grid-column: 1/2;
  -ms-grid-row: 1;
  grid-row: 1;
}
.water-tower-2 .blocks .block:nth-child(2n) .img img {
  margin-left: -16.6666666667%;
}

.people-list .subtitle {
  font-size: 1rem;
  position: relative;
  margin-bottom: 1.7rem;
}
.people-list .subtitle:before {
  content: "";
  display: block;
  position: absolute;
  right: 100%;
  margin-right: 1rem;
  width: 1.6rem;
  border-top: 1px solid #53565A;
  margin-top: -1px;
  top: 50%;
}
@media (max-width: 599px) {
  .people-list .subtitle:before {
    margin-right: 4px;
    width: 8px;
  }
}
.people-list .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #F7F7F7;
  padding: 0 3rem 1.1rem 3rem;
  line-height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  background: #fff;
}
.people-list .bottom:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 599px) {
  .people-list .bottom {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.people-list .bottom .tags {
  float: left;
}
.people-list .bottom .author {
  margin-top: 1.1rem;
  color: #BEE0EA;
  float: right;
}
.people-list .bottom .author img {
  vertical-align: middle;
  height: 2rem;
  margin-left: 1rem;
}
.people-list .people-intro {
  background: url("../assets/shape-2.png") no-repeat 0 100%;
  background-size: 100% auto;
  color: #fff;
  padding-top: 8rem;
  padding-bottom: 23rem;
}
.people-list .people-intro .container:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
.people-list .people-intro .container .content {
  max-width: 37rem;
  float: left;
  width: 48%;
}
@media (max-width: 599px) {
  .people-list .people-intro .container .content {
    width: 100%;
    float: none;
  }
}
.people-list .people-intro .container .content .title {
  font-weight: 600;
  margin-bottom: 1rem;
}
.people-list .people-intro .container .filters {
  max-width: 37rem;
  float: right;
  width: 48%;
}
@media (max-width: 599px) {
  .people-list .people-intro .container .filters {
    float: none;
    width: 100%;
    margin-top: 3.6rem;
  }
}
.people-list .people-intro .container .filters .search-form {
  position: relative;
}
.people-list .people-intro .container .filters .search-form input {
  display: block;
  width: 100%;
  background: #fff;
  padding: 1.2rem 5rem 1.2rem 3rem;
  border-radius: 100px;
  border: none;
  box-shadow: none;
  color: #53565A;
}
.people-list .people-intro .container .filters .search-form input::-webkit-input-placeholder {
  color: #BEE0EA !important;
}
.people-list .people-intro .container .filters .search-form input::-moz-placeholder {
  color: #BEE0EA !important;
}
.people-list .people-intro .container .filters .search-form input:-ms-input-placeholder {
  color: #BEE0EA !important;
}
.people-list .people-intro .container .filters .search-form input:-moz-placeholder {
  color: #BEE0EA !important;
}
.people-list .people-intro .container .filters .search-form .btn {
  width: 2.4rem;
  height: 2.4rem;
  background: url("../assets/search.png") no-repeat center center;
  background-size: 100% auto;
  position: absolute;
  right: 1.8rem;
  top: 50%;
  margin-top: -1.2rem;
  cursor: pointer;
}
.people-list .people-intro .container .filters .tags {
  font-size: 1rem;
  margin-top: 2.5rem;
}
.people-list .people-intro .container .filters .tags span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.people-list .people-intro .container .filters .tags span:after {
  width: 1em;
  display: inline-block;
  padding-left: 1rem;
  content: "+" !important;
}
.people-list .people-intro .container .filters .tags span.active:after {
  content: "x" !important;
}
.people-list .people-intro + *, .people-list .people-intro + *.hidden + * {
  margin-top: -18rem;
}
.people-list .people-intro + * .subtitle, .people-list .people-intro + *.hidden + * .subtitle {
  color: #fff;
}
.people-list .people-intro + * .subtitle:before, .people-list .people-intro + *.hidden + * .subtitle:before {
  border-top-color: #fff;
}
.people-list .people-featured {
  margin-bottom: 3.3rem;
}
.people-list .people-featured.hidden {
  display: none !important;
}
.people-list .people-featured .container-in {
  max-width: 90.5rem;
  margin: 0 auto;
}
.people-list .people-featured .subtitle {
  color: #fff;
}
.people-list .people-featured .subtitle:before {
  border-top-color: #fff;
}
.people-list .people-featured .people-block {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1.6rem;
  grid-row-gap: 1.6rem;
  background: #fff;
  min-height: 28rem;
}
.people-list .people-featured .people-block.hidden {
  display: none !important;
}
@media (max-width: 599px) {
  .people-list .people-featured .people-block {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
  }
}
.people-list .people-featured .people-block .img {
  -ms-grid-column: 1;
}
.people-list .people-featured .people-block .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.people-list .people-featured .people-block .text {
  -ms-grid-column: 2;
  padding-top: 3rem;
  padding-bottom: 6rem;
  position: relative;
}
@media (max-width: 599px) {
  .people-list .people-featured .people-block .text {
    padding-top: 14px;
    padding-bottom: 0;
  }
  .people-list .people-featured .people-block .text .bottom {
    position: static;
  }
}
.people-list .people-featured .people-block .text .title {
  margin-bottom: 2rem;
  font-weight: 700;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 599px) {
  .people-list .people-featured .people-block .text .title {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.people-list .people-featured .people-block .text .title a {
  color: inherit;
}
.people-list .people-featured .people-block .text .content {
  font-size: 1.2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 599px) {
  .people-list .people-featured .people-block .text .content {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.people-list .people-newest {
  margin-bottom: 18vw;
}
.people-list .people-newest .container-in {
  max-width: 90.5rem;
  margin: 0 auto;
}
.people-list .people-newest .blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1.6rem;
}
@media (max-width: 599px) {
  .people-list .people-newest .blocks {
    grid-template-columns: 1fr;
  }
}
.people-list .people-newest .blocks .people-block {
  margin-bottom: 1.6rem;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.people-list .people-newest .blocks .people-block.hidden {
  display: none !important;
}
.people-list .people-newest .blocks .people-block .bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/gradient-1.png") repeat-x 0 100%;
  background-size: auto 100%;
  z-index: 1;
}
.people-list .people-newest .blocks .people-block .content {
  z-index: 3;
  display: flex;
  align-items: flex-end;
  min-height: 28rem;
  padding-top: 3rem;
  position: relative;
  color: #fff;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
}
.people-list .people-newest .blocks .people-block .content .content-in {
  width: 100%;
}
.people-list .people-newest .blocks .people-block .content:after {
  font-size: 0;
  content: "";
  min-height: inherit;
}
.people-list .people-newest .blocks .people-block .content .title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 599px) {
  .people-list .people-newest .blocks .people-block .content .title {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.people-list .people-newest .blocks .people-block .content .title a {
  color: inherit;
}
.people-list .people-newest .blocks .people-block .content .text {
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 599px) {
  .people-list .people-newest .blocks .people-block .content .text {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.people-list .people-newest .blocks .people-block .content .bottom {
  padding-left: 3rem;
  padding-right: 3rem;
  position: static;
  margin-top: 2rem;
}
@media (max-width: 599px) {
  .people-list .people-newest .blocks .people-block .content .bottom {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.people-list.search-state .people-featured {
  display: none;
}
.people-list.search-state .people-newest .subtitle {
  opacity: 0;
}

.people-single {
  background-position: 50% 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 22vw;
}
.people-single .text {
  max-width: 90.4rem;
  margin: 0 auto;
}
.people-single .text:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
.people-single .text .content {
  background: #fff;
  padding: 2.4rem 10.1769911504%;
}
@media (max-width: 599px) {
  .people-single .text .content {
    padding: 14px;
  }
}
.people-single .text .content .big-img {
  width: 125.5555555556%;
  margin-left: -12.7777777778%;
  max-width: none;
  margin-bottom: 1rem;
}
@media (max-width: 599px) {
  .people-single .text .content .big-img {
    width: calc(100% + 28px);
    margin-left: -14px;
  }
}
.people-single .text .content .img-desc {
  display: block;
  margin-bottom: 0.6rem;
  font-size: 1rem;
  text-align: right;
  color: #53565A;
  opacity: 0.5;
}
.people-single .text .content .people-avatar {
  max-width: 8rem;
  display: block;
  margin: 0 auto 1.4rem auto;
}
.people-single .text .content .people-name {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-weight: 600;
  opacity: 0.8;
}
.people-single .text .content .people-position {
  display: block;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.6;
}
.people-single .text .content .title {
  font-weight: 700;
  margin-bottom: 1.1428571429em;
  position: relative;
  background: #FAFAFA;
  padding: 4rem;
  text-align: center;
}
@media (max-width: 767px) {
  .people-single .text .content .title {
    padding: 3rem;
  }
}
@media (max-width: 599px) {
  .people-single .text .content .title {
    padding: 2rem;
  }
}
.people-single .text .content .title:before {
  content: "„";
  display: block;
  position: absolute;
  right: 100%;
  bottom: 100%;
  font-size: 5rem;
  line-height: 1;
  margin-right: -0.3em;
  margin-bottom: -0.3em;
  opacity: 0.2;
}
.people-single .text .content .title:after {
  content: "„";
  display: block;
  position: absolute;
  left: 100%;
  top: 100%;
  font-size: 5rem;
  line-height: 1;
  margin-left: -0.3em;
  margin-top: -0.8em;
  opacity: 0.2;
}
.people-single .text .content .tags {
  display: table;
  margin: 0 auto 3.8rem auto;
}
.people-single .text .content hr {
  border-color: #E8E8E8;
}
.people-single .text .content strong {
  font-weight: 600;
  color: #0085AD;
}
.people-single .text .content .gallery {
  margin-top: 2.5em;
}
.people-single .text .content .gallery .subtitle {
  font-size: 1rem;
  position: relative;
  margin-bottom: 1.7rem;
  display: block;
}
.people-single .text .content .gallery .subtitle:before {
  content: "";
  display: block;
  position: absolute;
  right: 100%;
  margin-right: 1rem;
  width: 1.6rem;
  border-top: 1px solid #53565A;
  margin-top: -1px;
  top: 50%;
}
@media (max-width: 599px) {
  .people-single .text .content .gallery .subtitle:before {
    margin-right: 4px;
    width: 8px;
  }
}
.people-single .text .content .gallery .imgs:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
.people-single .text .content .gallery .imgs a {
  width: 23.3333333333%;
  margin: 0 0 2.2222222222% 2.2222222222%;
  float: left;
  display: block;
  overflow: hidden;
}
.people-single .text .content .gallery .imgs a:nth-child(4n+1) {
  clear: left;
  margin-left: 0;
}
@media (max-width: 599px) {
  .people-single .text .content .gallery .imgs a {
    width: 48%;
    margin: 0 0 4% 4%;
  }
  .people-single .text .content .gallery .imgs a:nth-child(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
.people-single .text .content .gallery .imgs a img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}
@media (min-width: 1025px) {
  .people-single .text .content .gallery .imgs a:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.people-single .text .content .videos video {
  display: block;
  height: auto;
  width: 125.5555555556%;
  margin-left: -12.7777777778%;
  max-width: none;
  margin-bottom: 4rem;
}
@media (max-width: 599px) {
  .people-single .text .content .videos video {
    width: calc(100% + 28px);
    margin-left: -14px;
  }
}
.people-single .text .content .videos-youtube {
  display: block;
  height: auto;
  width: 125.5555555556%;
  margin-left: -12.7777777778%;
  max-width: none;
  margin-bottom: 4rem;
}
@media (max-width: 599px) {
  .people-single .text .content .videos-youtube {
    width: calc(100% + 28px);
    margin-left: -14px;
  }
}
.people-single .text .content .videos-youtube .video-el {
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 56.25%;
}
.people-single .text .content .videos-youtube .video-el embed, .people-single .text .content .videos-youtube .video-el iframe, .people-single .text .content .videos-youtube .video-el object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.people-single .text .share-it {
  margin-top: 2.4rem;
  float: right;
  color: #0085AD;
  text-align: right;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.people-single .text .share-it span {
  display: block;
  float: left;
}
.people-single .text .share-it a {
  display: block;
  float: left;
  margin: 0 0 1.6rem 1.6rem;
  opacity: 0.8;
}
.people-single .text .share-it a img {
  display: block;
  height: 1.6rem;
  width: auto;
  max-width: none;
}
@media (min-width: 1025px) {
  .people-single .text .share-it a:hover {
    opacity: 1;
  }
}

.tags {
  line-height: 2rem;
  font-size: 1rem;
  font-weight: 600;
}
.tags span {
  border-radius: 100px;
  display: inline-block;
  min-width: 5rem;
  padding: 0 1rem;
  text-align: center;
  margin: 1.1rem 1.4rem 0 0;
  border: 1px solid #F7F7F7;
  background: #F7F7F7;
  color: #BEE0EA;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.people-intro .tags span {
  cursor: pointer;
}
.tags span:last-child {
  margin-right: 0;
}
.tags span.country {
  color: #009A44;
  border: 1px solid #009A44;
}
.tags span.country.active {
  background: #009A44;
  color: #fff !important;
}
.tags span.country.kh {
  border-color: #E57200;
}
.tags span.country.kh.active {
  background: #E57200;
}
.tags span.country.ph {
  border-color: #BF0D3E;
}
.tags span.country.ph.active {
  background: #BF0D3E;
}
.tags span.year {
  color: #009A44;
  border: 1px solid #009A44;
}
.tags span.year.active {
  background: #009A44;
  color: #fff !important;
}
.tags span.year.year-2015 {
  border-color: #E57200;
}
.tags span.year.year-2015.active {
  background: #E57200;
}
.tags span.year.year-2016 {
  border-color: #BF0D3E;
}
.tags span.year.year-2016.active {
  background: #BF0D3E;
}
.tags span.add-country {
  background: none;
  color: #615E9B;
  border: 1px solid #615E9B;
}

.sections.about-page {
  padding-top: 0;
  padding-bottom: 0 !important;
}

section.about {
  color: #fff;
  text-align: center;
  background: #1E95AE;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
section.about .scroll-down {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 6rem;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
}
section.about .scroll-down:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  bottom: -6rem;
  height: 4rem;
  border-left: 1px solid #fff;
}
@media (max-width: 767px) {
  section.about .scroll-down {
    bottom: 40px;
  }
  section.about .scroll-down:before {
    bottom: -40px;
    height: 34px;
  }
}
section.about .switcher {
  position: absolute;
  font-size: 12px;
  bottom: 6rem;
  right: 9rem;
  font-weight: 700;
}
@media (max-width: 767px) {
  section.about .switcher {
    right: 14px;
    bottom: 14px;
  }
}
section.about .switcher img {
  cursor: pointer;
  display: block;
  width: 16px;
  height: auto;
  margin: 4px auto;
}
section.about .facts {
  position: absolute;
  top: 4.5rem;
  left: 14rem;
  text-transform: uppercase;
  line-height: 1;
}
section.about .facts:before {
  content: "";
  right: 100%;
  margin-right: 1rem;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  border-top: 1px solid #fff;
  display: block;
  width: 6rem;
}
@media (max-width: 767px) {
  section.about .facts {
    top: 100px;
    left: 44px;
  }
  section.about .facts:before {
    margin-right: 10px;
    width: 20px;
  }
}
section.about .subtext {
  position: absolute;
  bottom: 7.7rem;
  right: 13rem;
  font-size: 1.2rem;
  display: block;
}
@media (max-width: 767px) {
  section.about .subtext {
    right: 42px;
    bottom: 31px;
  }
}
@media (max-width: 599px) {
  section.about .subtext {
    bottom: 32px;
  }
}
section.about .container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
}
section.about .container .text {
  max-width: 84rem;
  padding: 10rem 2rem;
  font-size: 3rem;
}
@media (max-width: 767px) {
  section.about .container .text {
    padding: 8rem 1rem;
  }
}
section.about .container .text .title {
  font-size: 17rem;
  line-height: 1;
}
@media (max-width: 767px) {
  section.about .container .text .title {
    font-size: 7rem;
    line-height: 1.2;
  }
}
section.about .container .text p {
  max-width: 40rem;
  margin: 0 auto;
}
section.about .container .text .title2 {
  display: table;
  table-layout: fixed;
  margin: 0 auto;
}
section.about .container .text .title2 span {
  padding: 0 1rem;
  line-height: 1;
  display: table-cell;
  vertical-align: middle;
}
section.about .container .text .title2 span:nth-child(1), section.about .container .text .title2 span:nth-child(3) {
  font-size: 17rem;
  padding-top: 0.2em;
}
@media (max-width: 767px) {
  section.about .container .text .title2 span:nth-child(1), section.about .container .text .title2 span:nth-child(3) {
    font-size: 7rem;
    line-height: 1.2;
  }
}
section.about .container .text .title2 span:nth-child(2) {
  font-size: 7.5rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  section.about .container .text .title2 span:nth-child(2) {
    font-size: 2rem;
  }
}
section.about.about-video-section video {
  width: 100%;
  height: auto;
  z-index: 3;
  display: block;
  position: relative;
  outline: none;
  max-height: 100vh;
}
.play-video section.about.about-video-section video {
  z-index: 10;
}
section.about.about-video-section .video-iframe {
  width: 100%;
  height: auto;
  position: relative;
  max-height: 100vh;
}
section.about.about-video-section .video-iframe:after {
  padding-top: 56.25%;
  content: "";
  display: block;
}
section.about.about-video-section .video-iframe embed, section.about.about-video-section .video-iframe iframe, section.about.about-video-section .video-iframe object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.play-video section.about.about-video-section .video-iframe {
  z-index: 10;
}
section.about.about-video-section video {
  width: 100%;
  height: auto;
  z-index: 3;
  display: block;
  position: relative;
  outline: none;
  max-height: 100vh;
}
section.about.about-video-section .about-poster {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #53565A url("../assets/about-poster.jpg") no-repeat center center;
  background-size: cover;
  z-index: 5;
  display: block;
}
.play-video section.about.about-video-section .about-poster {
  filter: blur(8px);
}
section.about.about-video-section .about-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 6rem;
  z-index: 6;
  display: none;
}
@media (max-width: 767px) {
  section.about.about-video-section .about-bottom {
    bottom: 2rem;
  }
}
section.about.about-video-section .about-bottom.with-video {
  display: block;
}
section.about.about-video-section .about-bottom #about-video-btn {
  display: table;
  margin: 0 auto 3rem auto;
  color: #0085AD;
  background: #fff;
  border-radius: 50px;
  font-size: 1.6rem;
  padding: 1.3rem 3.9rem;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 1;
  visibility: visible;
}
section.about.about-video-section .about-bottom .scroll-down-arr {
  margin: 0 auto;
  cursor: pointer;
  width: 2.8rem;
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 1;
  visibility: visible;
}
section.about.about-video-section .about-bottom .scroll-down-arr img {
  display: block;
  width: 100%;
  -webkit-animation: updown 0.6s linear 0s infinite alternate;
  animation: updown 0.6s linear 0s infinite alternate;
}
@-webkit-keyframes updown {
  from {
    -webkit-transform: translateY(-2px);
  }
  to {
    -webkit-transform: translateY(2px);
  }
}
@keyframes updown {
  from {
    transform: translateY(-2px);
  }
  to {
    transform: translateY(2px);
  }
}
section.about.about-1 {
  background-image: url("../assets/about-1.jpg");
}
section.about.about-1 .container .text .title {
  font-size: 4.5rem;
  line-height: 1.2;
}
@media (max-width: 767px) {
  section.about.about-1 .container .text .title {
    font-size: 4rem;
  }
}
section.about.about-2 {
  background-image: url("../assets/about-2.jpg");
}
section.about.about-3 {
  background-image: url("../assets/about-3.jpg");
}
section.about.about-4 {
  background-image: url("../assets/about-4.jpg");
}
section.about.about-5 {
  background-image: url("../assets/about-5.jpg");
}
section.about.about-6 {
  background-image: url("../assets/about-6.jpg");
}
section.about.about-7 {
  background-image: url("../assets/about-7.jpg");
}
section.about.about-8 {
  background-image: url("../assets/about-8.jpg");
}
section.about.about-9 {
  background-image: url("../assets/about-9.jpg");
}
section.about.about-10 {
  background-image: url("../assets/about-10.jpg");
}

@media (max-width: 1023px) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 599px) {
  html {
    font-size: 50%;
  }
}
footer#footer {
  background: #56C1C6;
  color: #DFF0F5;
  padding: 6rem 0 3rem 0;
  font-size: 1.2rem;
}
@media (max-width: 767px) {
  footer#footer {
    font-size: 12px;
  }
}
@media (max-width: 599px) {
  footer#footer {
    padding: 28px 0;
  }
}
footer#footer strong {
  color: #fff;
  font-weight: 600;
}
footer#footer .disclaimer {
  float: left;
  margin-left: 33.3333333333%;
}
@media (max-width: 767px) {
  footer#footer .disclaimer {
    margin: 0;
    width: 50%;
    padding-right: 14px;
  }
}
footer#footer .social-links {
  width: 41.6666666667%;
  float: right;
}
@media (max-width: 767px) {
  footer#footer .social-links {
    width: 50%;
    padding-left: 14px;
  }
}
footer#footer .social-links strong {
  display: block;
  margin-bottom: 1.5em;
}
footer#footer .social-links a {
  float: left;
  margin: 0 1.6rem 1.6rem 0;
  opacity: 0.8;
}
footer#footer .social-links a img {
  display: block;
  height: 1.6rem;
  width: auto;
  max-width: none;
}
@media (min-width: 1025px) {
  footer#footer .social-links a:hover {
    opacity: 1;
  }
}
footer#footer .lang-switcher {
  clear: both;
  padding-top: 3rem;
}
@media (max-width: 767px) {
  footer#footer .lang-switcher {
    padding-top: 28px;
  }
}
footer#footer .lang-switcher ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: table;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
}
@media (max-width: 767px) {
  footer#footer .lang-switcher ul {
    padding-left: 0;
    padding-right: 0;
  }
}
footer#footer .lang-switcher ul:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 10%;
  width: 80%;
  border-bottom: 1px solid #fff;
  opacity: 0.1;
}
footer#footer .lang-switcher ul:after {
  position: absolute;
  content: "";
  display: block;
  bottom: 0;
  left: 10%;
  width: 80%;
  border-top: 1px solid #fff;
  opacity: 0.1;
}
footer#footer .lang-switcher ul li {
  float: left;
  margin-left: 3rem;
}
footer#footer .lang-switcher ul li:first-child {
  margin-left: 0 !important;
}
@media (max-width: 767px) {
  footer#footer .lang-switcher ul li {
    margin-left: 14px;
  }
}
footer#footer .lang-switcher ul li a, footer#footer .lang-switcher ul li span {
  cursor: pointer;
  color: #BEE0EA;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (min-width: 1025px) {
  footer#footer .lang-switcher ul li a:hover, footer#footer .lang-switcher ul li span:hover {
    color: #fff;
  }
}
footer#footer .bottom {
  padding-top: 3rem;
  font-size: 1rem;
  text-transform: uppercase;
  color: #BEE0EA;
}
footer#footer .bottom:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}
@media (max-width: 767px) {
  footer#footer .bottom {
    font-size: 10px;
  }
}
@media (max-width: 599px) {
  footer#footer .bottom {
    margin-top: 28px;
  }
}
footer#footer .bottom > div {
  width: 33.3333%;
  float: left;
}
footer#footer .bottom > div:nth-child(1) {
  padding-right: 2%;
  text-align: left;
}
footer#footer .bottom > div:nth-child(2) {
  padding-left: 1%;
  text-align: center;
  padding-right: 1%;
}
footer#footer .bottom > div:nth-child(3) {
  padding-left: 2%;
  text-align: right;
}
@media (max-width: 599px) {
  footer#footer .bottom > div {
    width: auto !important;
    float: none !important;
    padding: 0 !important;
    text-align: left !important;
    margin-top: 14px;
  }
  footer#footer .bottom > div:first-child {
    margin-top: 0;
  }
}
footer#footer .bottom a {
  color: #BEE0EA;
}
@media (min-width: 1025px) {
  footer#footer .bottom a:hover {
    color: #fff;
  }
}
